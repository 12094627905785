import React from 'react';
import {Button, Box, Grid} from '@mui/material';
import {
  mainWholePage,
  mainBox1,
  mainBox2,
  iconBox,
  mainText1,
  mainText2,
  mainText3,
  mainText4,
} from 'view/main/MobileStyles';
import HeaderView from 'view/common/MobileHeader';
import FooterView from 'view/common/MobileFooter';
import imageMain2 from 'asset/imageV2/image_main2.png';
import imageMain4 from 'asset/imageV2/image_main4.png';

const MainPage = () => {
  return (
    <Box style={mainWholePage}>
      <HeaderView type={1} />
      <Box style={mainBox1}>
        <Box style={mainText1}>간편하게 제출하고</Box>
        <Box style={mainText1}>꼼꼼히 첨삭받자!</Box>
        <Box mt={'1rem'} style={mainText2}>
          키위런
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'end',
          }}
        >
          <img src={imageMain4} alt={'imageMain4'} style={{width: '70%'}} />
        </Box>
      </Box>
      <Box style={mainBox2}>
        <Box mt={'3rem'} style={mainText3}>
          6가지 지표의 AI 피드백
        </Box>
        <Box style={mainText4}>반영해서 완성하는 성취감</Box>
        <Box mt={'1.5rem'} mb={'4rem'}>
          <img
            src={imageMain2}
            alt="imageMain2"
            style={{
              marginLeft: '10%',
              width: '80%',
              objectFit: 'contain',
            }}
          />
        </Box>
      </Box>
      <FooterView />
    </Box>
  );
};
export default MainPage;
