import {coloredStyle} from './Styles';
import {
  Palette,
  ColorUtilsAnaly1,
  ColorUtilsLabel1,
  ColorUtilsAnaly2,
  ColorUtilsLabel2,
} from 'common/utils/ColorUtils';
import {AnalysisConstants} from 'common/utils/AnalysisConstants';
import {NumberUtils} from 'common/utils/NumberUtils';
import OverallComment from 'asset/json/overall_comment';
const backgroundOption = [
  '#C6DBDA',
  '#FEE1E8',
  '#FED7C3',
  '#F6EAC2',
  '#ECD5E3',
  '#D4F0F0',
  '#8FCACA',
  '#CCE2CB',
  '#FCB9AA',
  '#FFDECC',
];
export const AnalysisText = {
  createHtmlTextLabel: function (type) {
    try {
      if (type == '주장' || type == '대안제시' || type == '찬성반대') {
        const keys = [
          '화제 제시',
          '주장',
          '근거',
          '예시',
          '주장 반박',
          '반박 재반박',
          '결론',
        ];
        return (
          <div
            style={{
              marginBottom: '2rem',
              fontSize: '0.8125rem',
              fontWeight: '500',
              color: '#4b4d4c',
            }}
          >
            {keys.map((item, index) => (
              <span key={index} style={{marginRight: '1rem'}}>
                <strong
                  style={coloredStyle.coloredLabel(ColorUtilsLabel1[item])}
                >
                  ■{' '}
                </strong>
                <span
                  style={{
                    fontSize: 'calc(0.3rem + 0.5vw)',
                  }}
                >
                  {item + '  '}
                </span>
              </span>
            ))}
          </div>
        );
      } else {
        const keys = ['화제 제시', '중심 내용', '마무리'];
        return (
          <div
            style={{
              marginBottom: '2rem',
              fontSize: '0.8125rem',
              fontWeight: '500',
              color: '#4b4d4c',
            }}
          >
            {keys.map((item, index) => (
              <span key={index} style={{marginRight: '1rem'}}>
                <strong
                  style={coloredStyle.coloredLabel(ColorUtilsLabel2[item])}
                >
                  ■{' '}
                </strong>
                <span style={{fontSize: 'calc(0.3rem + 0.5vw)'}}>
                  {item + '  '}
                </span>
              </span>
            ))}
          </div>
        );
      }
    } catch (e) {
      console.error(e);
      return '';
    }
  },

  createHtmlTextAnaly: function (paragraph_texts, type) {
    let isOpinion = false;
    if (type == '주장' || type == '대안제시' || type == '찬성반대') {
      isOpinion = true;
    }
    try {
      return (
        <div>
          {paragraph_texts.map((paragraph, index) => (
            <div key={index}>
              {paragraph.map((sentence, index2) => (
                <span key={index2}>
                  <span
                    style={coloredStyle.coloredText(
                      isOpinion
                        ? ColorUtilsAnaly1[sentence.tag]
                        : ColorUtilsAnaly2[sentence.tag],
                    )}
                  >
                    {sentence.text}
                  </span>
                  <span> </span>
                </span>
              ))}
            </div>
          ))}
        </div>
      );
    } catch (e) {
      console.error(e);
      return '';
    }
  },
  createCommentFromSaved: function (wholeText, commentList) {
    const result = [];
    const addItem = {
      type: 'add',
    };

    const splitedParagraph = this.splitIntoParagraph(wholeText);
    for (let i = -1; i < splitedParagraph.length; i++) {
      let commentArray = [];
      if (commentList[i + 1] != undefined) {
        commentArray = commentList[i + 1];
      } else {
        break;
      }
      const paragraphArray = [];
      if (i != -1) {
        const paragraphWithNumber = this.createParagrphWithNumbers(
          splitedParagraph[i],
          commentArray,
        );
        if (i == 0) {
          const titleItem1 = {
            type: 'title',
            A: '문단별 첨삭',
            B: 'KEEwiT 첨삭을 바탕으로 리포트를 완성해 보세요.',
            C: Palette.grey3,
          };
          paragraphArray.push(titleItem1);
        }

        const paraItem = {
          type: 'paragraph',
          text: paragraphWithNumber,
        };
        paragraphArray.push(paraItem);
      }
      const tempArray = [...paragraphArray, ...commentArray];
      tempArray.push(addItem);
      result.push(tempArray);
    }
    return result;
  },

  createParagrphWithNumbers(wholeText, trait_comment) {
    let resultArray = [];
    let filteredComment = [];
    if (trait_comment != undefined) {
      filteredComment = trait_comment.filter(item => item.index.length != 0);
    }
    const orderedComment = [];
    for (let i = 0; i < filteredComment.length; i++) {
      for (let j = 0; j < filteredComment[i].index.length; j++) {
        const item = {
          commentID: filteredComment[i].commentID,
          index: filteredComment[i].index[j],
          type: filteredComment[i].type,
          textlength: filteredComment[i].textlength,
          color: backgroundOption[i % backgroundOption.length],
        };
        orderedComment.push(item);
      }
    }
    orderedComment.sort((a, b) => {
      if (a.index == b.index && a.commentID == -1 && b.commentID != -1) {
        return 1;
      }
      if (a.index == b.index && a.commentID != -1 && b.commentID == -1) {
        return -1;
      }
      return a.index - b.index;
    });
    let prevIndex = 0;
    for (let i = 0; i < orderedComment.length; i++) {
      if (prevIndex > orderedComment[i].index) {
        continue;
      }
      resultArray.push(
        <span key={'para' + i}>
          {wholeText.slice(prevIndex, orderedComment[i].index)}
        </span>,
      );
      if (
        orderedComment[i].textlength != undefined &&
        orderedComment[i].textlength != 0
      ) {
        resultArray.push(
          <span
            key={'coloring' + i}
            style={{
              backgroundColor: orderedComment[i].color,
            }}
          >
            {wholeText.slice(
              orderedComment[i].index,
              orderedComment[i].index + orderedComment[i].textlength,
            )}
          </span>,
        );
      } else {
        resultArray.push(
          <span
            key={'numbering' + i}
            style={{
              verticalAlign: 'top',
              color: orderedComment[i].type == 'ABC' ? '#ff6928' : '#3DB49E',
              fontSize: '0.7rem',
              fontWeight: 'bold',
            }}
          >
            {'(' + orderedComment[i].commentID + ') '}
          </span>,
        );
      }
      prevIndex = orderedComment[i].index;
      if (orderedComment[i].textlength != undefined) {
        prevIndex += orderedComment[i].textlength;
      }
    }
    if (orderedComment.length == 0) {
      resultArray.push(<span key={'all'}>{wholeText}</span>);
    } else {
      resultArray.push(
        <span key={'paralast'}>{[wholeText.slice(prevIndex)]}</span>,
      );
    }
    return resultArray;
  },
  createParagraphComment(wholeText, paragraph_list, trait_comment) {
    const result = [];

    const splitedParagraph = this.splitIntoParagraph(wholeText);
    let commentCount = 1;
    for (let i = -1; i < paragraph_list.length; i++) {
      const foundComment = trait_comment.filter(item => item.pid == i);
      let prevTextLength = 0;
      if (i > 0) {
        prevTextLength = splitedParagraph.slice(0, i).join('\n').length + 1;
      }
      const sortedComment = this.getSortedComment(
        splitedParagraph[i],
        paragraph_list[i],
        foundComment,
        commentCount,
        prevTextLength,
      );

      commentCount += sortedComment.filter(
        item => item.index.length != 0,
      ).length;
      const paragraphArray = [];
      if (i != -1) {
        const paragraphWithNumber = this.createParagrphWithNumbers(
          splitedParagraph[i],
          sortedComment,
        );

        const paraItem = {
          type: 'paragraph',
          text: paragraphWithNumber,
        };
        paragraphArray.push(paraItem);
      }
      const tempArray = [...paragraphArray, ...sortedComment];

      result.push(tempArray);
    }
    return result;
  },
  createComment(wholeText, paragraph_list, trait_comment) {
    const result = [];
    const addItem = {
      type: 'add',
    };
    const splitedParagraph = this.splitIntoParagraph(wholeText);
    let commentCount = 1;
    for (let i = -1; i < paragraph_list.length; i++) {
      const foundComment = trait_comment.filter(item => item.pid == i);
      let prevTextLength = 0;
      if (i > 0) {
        prevTextLength = splitedParagraph.slice(0, i).join('\n').length + 1;
      }
      const sortedComment = this.getSortedComment(
        splitedParagraph[i],
        paragraph_list[i],
        foundComment,
        commentCount,
        prevTextLength,
      );

      commentCount += sortedComment.filter(
        item => item.index.length != 0,
      ).length;
      const paragraphArray = [];
      if (i != -1) {
        const paragraphWithNumber = this.createParagrphWithNumbers(
          splitedParagraph[i],
          sortedComment,
        );
        if (i == 0) {
          const titleItem1 = {
            type: 'title',
            A: '문단별 첨삭',
            B: 'KEEwiT 첨삭을 바탕으로 리포트를 완성해 보세요.',
            C: Palette.grey3,
          };
          paragraphArray.push(titleItem1);
        }
        const paraItem = {
          type: 'paragraph',
          text: paragraphWithNumber,
        };
        paragraphArray.push(paraItem);
      }
      const tempArray = [...paragraphArray, ...sortedComment];
      tempArray.push(addItem);
      result.push(tempArray);
    }
    return result;
  },
  getSortedComment(
    paragraph_text,
    paragraph_list,
    foundComment,
    commentCount,
    prevTextLength,
  ) {
    const withSid = foundComment.filter(item => item.sid != -1);
    let extractedWords = [];

    for (let i = 0; i < withSid.length; i++) {
      const searchText = paragraph_list[withSid[i].sid].text;
      const indexArray = this.getIndicesOf(searchText, paragraph_text);
      const item = {
        index: indexArray,
        type: withSid[i].type,
        subtype: withSid[i].subtype,
        A: withSid[i]['A'],
        B: withSid[i]['B'],
        C: withSid[i]['C'],
      };
      extractedWords.push(item);
    }
    const ABCOnly = foundComment.filter(
      item => item.type == 'ABC' && item.sid == -1,
    );
    for (let i = 0; i < ABCOnly.length; i++) {
      const indexArray = this.getIndicesOf(ABCOnly[i].A, paragraph_text);
      const item = {
        index: indexArray,
        type: ABCOnly[i].type,
        subtype: ABCOnly[i].subtype,
        A: ABCOnly[i]['A'],
        B: ABCOnly[i]['B'],
        C: ABCOnly[i]['C'],
      };
      extractedWords.push(item);
    }

    const overused = foundComment.filter(
      item =>
        item.type != 'ABC' &&
        item.sid == -1 &&
        item.overused_word_indices != null &&
        item.overused_word_indices != undefined,
    );

    for (let i = 0; i < overused.length; i++) {
      const item = {
        index: overused[i].overused_word_indices.map(
          item => item[0] - prevTextLength,
        ),
        textlength:
          overused[i].overused_word_indices[0][1] -
          overused[i].overused_word_indices[0][0],
        type: overused[i].type,
        subtype: overused[i].subtype,
        A: overused[i]['A'],
        B: overused[i]['B'],
        C: overused[i]['C'],
      };
      extractedWords.push(item);
    }
    const Rest = foundComment.filter(
      item =>
        item.type != 'ABC' &&
        item.sid == -1 &&
        (item.overused_word_indices == null ||
          item.overused_word_indices == undefined),
    );
    for (let i = 0; i < Rest.length; i++) {
      const item = {
        index: [],
        type: Rest[i].type,
        subtype: Rest[i].subtype,
        A: Rest[i]['A'],
        B: Rest[i]['B'],
        C: Rest[i]['C'],
      };
      extractedWords.push(item);
    }
    extractedWords.sort((a, b) => {
      if (a.type.length == b.type.length) {
        if (a.index.length == 0 && b.index.length == 0) {
          if (a.subtype == 'help' && b.subtype != 'help') {
            return -1;
          }
          if (a.subtype != 'help' && b.subtype == 'help') {
            return 1;
          }
          if (a.subtype == 'point' && b.subtype == 'good') {
            return -1;
          }
          if (a.subtype == 'good' && b.subtype == 'point') {
            return 1;
          }
        } else if (b.index.length == 0) {
          return -1;
        } else if (a.index.length == 0) {
          return 1;
        } else {
          return a.index[0] > b.index[0] ? 1 : -1;
        }
      } else {
        return b.type.length - a.type.length;
      }
    });
    for (let i = 0; i < extractedWords.length; i++) {
      if (extractedWords[i].index.length == 0 || extractedWords[i].textlength) {
        extractedWords[i].commentID = -1;
      } else {
        extractedWords[i].commentID = commentCount;
        commentCount++;
      }
    }
    return extractedWords;
  },
  splitIntoParagraph(wholeText) {
    return wholeText.split('\n');
  },
  getIndicesOf(searchStr, str) {
    let searchStrLen = searchStr.length;
    if (searchStrLen == 0) {
      return [];
    }
    let startIndex = 0,
      index,
      indices = [];
    while ((index = str.indexOf(searchStr, startIndex)) > -1) {
      indices.push(index);
      startIndex = index + searchStrLen;
    }
    return indices;
  },
  getAlphbeticScore: function (num) {
    if (num == 9) {
      return 'A+';
    } else if (num == 8) {
      return 'A';
    } else if (num == 7) {
      return 'B+';
    } else if (num == 6) {
      return 'B';
    } else if (num == 5) {
      return 'C+';
    } else if (num == 4) {
      return 'C';
    } else if (num == 3) {
      return 'D+';
    } else if (num == 2) {
      return 'D';
    } else if (num == 1) {
      return 'F';
    } else {
      return 'F';
    }
  },
  getNumericScore: function (alpha) {
    if (alpha == 'A+') {
      return 9;
    } else if (alpha == 'A') {
      return 8;
    } else if (alpha == 'B+') {
      return 7;
    } else if (alpha == 'B') {
      return 6;
    } else if (alpha == 'C+') {
      return 5;
    } else if (alpha == 'C') {
      return 4;
    } else if (alpha == 'D+') {
      return 3;
    } else if (alpha == 'D') {
      return 2;
    } else if (alpha == 'F') {
      return 1;
    } else {
      return 0;
    }
  },
  calculateTscore: function (alpha1, alpha2, alpha3, alpha4, alpha5, alpha6) {
    const sum =
      this.getNumericScore(alpha1) +
      this.getNumericScore(alpha2) +
      this.getNumericScore(alpha3) +
      this.getNumericScore(alpha4) +
      this.getNumericScore(alpha5) +
      this.getNumericScore(alpha6);
    return NumberUtils.getAnalysisNumber((sum / 54) * 100);
  },
  calculateTscoreNumeric: function (
    numeric1,
    numeric2,
    numeric3,
    numeric4,
    numeric5,
    numeric6,
  ) {
    const sum = numeric1 + numeric2 + numeric3 + numeric4 + numeric5 + numeric6;
    return NumberUtils.getAnalysisNumber((sum / 54) * 100);
  },
  createSavingJson: function (
    commentParagraph,
    tScore,
    titleText,
    ageText,
    nameText,
    writingJanre,
    keewiWc,
    overallComment,
    toggleComment,
    toggle1,
    toggle2,
    toggle3,
    toggle4,
    toggle5,
    toggle6,
    keewiScore,
    originalData,
  ) {
    const copyComment = [...commentParagraph];
    const filteredComment = copyComment.map((paragraphArray, index) => {
      return paragraphArray
        .filter(
          item => item.type == 'ABC' || item.type == 'AC' || item.type == 'C',
        )
        .map((val, idx) => {
          val.A = val.A ?? null;
          val.B = val.B ?? null;
          val.C = val.C ?? null;
          val.removed = val.removed ?? false;
          return val;
        });
    });
    let numTscore = Number(tScore);
    if (isNaN(numTscore)) {
      numTscore = 0;
    }
    const gatteredDict = {
      score: numTscore,
      title: titleText,
      student_age: ageText,
      student_name: nameText,
      writing_janre: writingJanre,
      word_cloud: keewiWc,
      comment: overallComment,
      trait_1_score: toggle1,
      trait_1_text: toggleComment[0],
      trait_2_score: toggle2,
      trait_2_text: toggleComment[1],
      trait_3_score: toggle3,
      trait_3_text: toggleComment[2],
      trait_4_score: toggle4,
      trait_4_text: toggleComment[3],
      trait_5_score: toggle5,
      trait_5_text: toggleComment[4],
      trait_6_score: toggle6,
      trait_6_text: toggleComment[5],
      commentList: filteredComment,
    };

    const result = {...gatteredDict, ...keewiScore, ...originalData};
    return result;
  },
  getOverallCommentFromJson: function (number) {
    let comment = '';
    if (number <= 10) {
      comment = OverallComment['10'];
    } else if (number <= 20) {
      comment = OverallComment['20'];
    } else if (number <= 30) {
      comment = OverallComment['30'];
    } else if (number <= 45) {
      comment = OverallComment['45'];
    } else if (number <= 50) {
      comment = OverallComment['50'];
    } else if (number <= 55) {
      comment = OverallComment['55'];
    } else if (number <= 60) {
      comment = OverallComment['60'];
    } else if (number <= 65) {
      comment = OverallComment['65'];
    } else if (number <= 70) {
      comment = OverallComment['70'];
    } else if (number <= 75) {
      comment = OverallComment['75'];
    } else if (number <= 80) {
      comment = OverallComment['80'];
    } else if (number <= 85) {
      comment = OverallComment['85'];
    } else if (number <= 90) {
      comment = OverallComment['90'];
    } else if (number <= 95) {
      comment = OverallComment['95'];
    } else if (number <= 100) {
      comment = OverallComment['100'];
    } else {
      comment = '';
    }
    return comment;
  },
  createSavingJsonFromEvalResult: function (
    result,
    titleText,
    bodyText,
    ageText,
    nameText,
  ) {
    const wholeComment = [
      ...result.trait_6_comment,
      ...result.trait_5_comment,
      ...result.trait_4_comment,
      ...result.trait_3_comment,
      ...result.trait_2_comment,
      ...result.trait_1_comment,
    ];
    const originalData = {
      originalText: bodyText,
      type: result.type,
      is_gpt_text: result.is_gpt_text,
      aes_score: result.aes_score,
      paragraph_texts: result.paragraph_texts,
    };
    const keewiScore = {
      겹치는단어비율: NumberUtils.getAnalysisNumber(
        result.겹치는단어비율 * 100,
      ),
      단어의평균길이: NumberUtils.getAnalysisNumber(result.단어의평균길이),
      문단별문장수: NumberUtils.getAnalysisNumber(result.문단별문장수),
      문장별단어수: NumberUtils.getAnalysisNumber(result.문장별단어수),
      분석오류문장수: result.분석오류문장수,
      전체글자수: result.전체글자수,
      // 전체글자수: textLength,
      전체문장수: result.전체문장수,
      전체문단수: result.전체문단수,
      흐름이자연스러운정도: NumberUtils.getAnalysisNumber(
        result.흐름이자연스러운정도 * 100,
      ),
      긴문장의수: result.긴문장의수,
      독자나이: result.독자나이,
    };
    const reactwc = result.word_cloud.map((item, index) => {
      return {text: item.name, value: item.weight};
    });
    const currentScore = NumberUtils.getAnalysisNumber(result.keewi_score);

    const toggleComment = [true, true, true, true, true, true];
    const jsonResult = this.createSavingJson(
      this.createComment(bodyText, result.paragraph_texts, wholeComment),
      currentScore,
      titleText,
      ageText,
      nameText,
      result.janre,
      reactwc,
      this.getOverallCommentFromJson(currentScore),
      toggleComment,
      this.getAlphbeticScore(result.trait_1_score),
      this.getAlphbeticScore(result.trait_2_score),
      this.getAlphbeticScore(result.trait_3_score),
      this.getAlphbeticScore(result.trait_4_score),
      this.getAlphbeticScore(result.trait_5_score),
      this.getAlphbeticScore(result.trait_6_score),
      keewiScore,
      originalData,
    );
    return jsonResult;
  },
  createHighlightWordList: function (trait_comment) {
    const result = trait_comment
      .filter(item => item.type == 'ABC' && item.sid == -1)
      .map(item => item.A);
    return result;
  },
  createHighlightWordListFromSaved: function (commentSaved) {
    let result = [];
    commentSaved.forEach(paragraphComment => {
      const temp = paragraphComment
        .filter(item => item.type == 'ABC')
        .map(item => item.A);
      result = [...result, ...temp];
    });
    return result;
  },
};
