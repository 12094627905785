import React from 'react';
import {Box, Button} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {saveLogin} from 'common/reducer/auth';
import GoogleButton from 'view/login/GoogleButton';
import NaverButton from 'view/login/NaverButton';
import KakaoButton from 'view/login/KakaoButton';
import HeaderView from 'view/common/Header';
import {
  wholeLoginPage,
  loginTitle1,
  loginTitle2,
  loginKakaoBox,
  loginNaverBox,
  loginGoogleBox,
  loginEnterpriseBox,
} from 'view/login/Styles';
import APIUtils from 'common/utils/APIUtils';
import FooterView from 'view/common/Footer';
import {useCookies} from 'react-cookie';
import {loginBox} from 'view/login/Styles';

const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authReducer = useSelector(state => state.authReducer);
  const [cookies, setCookie] = useCookies(['lastTime']);

  function onGoogleSuccess(token) {
    callLoginAPI('google', token);
  }
  function onNaverSuccess(token) {
    callLoginAPI('naver', token);
  }
  function onKakaoSuccess(token) {
    callLoginAPI('kakao', token);
  }
  function callLoginAPI(access_path, access_token) {
    const loginResult = async (path, token) => {
      try {
        const response = await APIUtils.AccountLogin(path, token);
        if (response.data.ret_code == 1000) {
          dispatch(
            saveLogin({
              user_type: response.data.user_type,
              student_id: response.data.student_id,
              student_name: response.data.student_name,
              student_email: response.data.student_email,
              path: path,
            }),
          );
          setCookie('lastTime', new Date());
          for (var i = 0; i < localStorage.length; i++) {
            var foundKey = localStorage.key(i);
            if (
              foundKey.includes('taskTitle') ||
              foundKey.includes('taskBody') ||
              foundKey.includes('task_id') ||
              foundKey.includes('practiceBody') ||
              foundKey.includes('practiceTitle') ||
              foundKey.includes('practiceJanre')
            ) {
              localStorage.removeItem(foundKey);
            }
          }
        }
      } catch (err) {
        console.log(err);
      }
    };
    loginResult(access_path, access_token).then(r => {
      APIUtils.LoggerLog(
        authReducer.user_id,
        'keewil',
        'info',
        window.location.pathname,
        'navigate_mypage',
      );
      navigate('/mypage');
    });
  }
  return (
    <Box style={wholeLoginPage}>
      <HeaderView />
      <Box style={{minHeight: '55vh'}}>
        <Box style={loginTitle1}>맞춤법 교정부터 문맥 파악까지</Box>
        <Box style={loginTitle2}>
          AI 피드백을 반영해서
          <br />
          완성하는 글쓰기, 키위런
        </Box>
        <Box style={loginKakaoBox}>
          <KakaoButton onSuccess={onKakaoSuccess} />
        </Box>
        <Box style={loginNaverBox}>
          <NaverButton onSuccess={onNaverSuccess} />
        </Box>
        <Box style={loginGoogleBox}>
          <GoogleButton onSuccess={onGoogleSuccess} />
        </Box>
        <Box style={loginEnterpriseBox}>
          <Button
            style={loginBox.coloredBox('#279eff')}
            onClick={() => {
              navigate('/login/enterprise');
            }}
          >
            ID/PW로 로그인하기
          </Button>
        </Box>
      </Box>
      <FooterView />
    </Box>
  );
};
export default LoginPage;
