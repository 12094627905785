import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {Button, Box, Grid} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import HeaderView from 'view/common/Header';
import backgroundMain1 from 'asset/imageV2/background_main1.svg';
import iconArrowRight from 'asset/imageV2/icon_arrow_right.png';
import imgMain1 from 'asset/imageV2/image_main1.png';
import imgMain2 from 'asset/imageV2/image_main2.png';
import imgMain3 from 'asset/imageV2/image_main3.png';
import {mainColoredBtn} from 'view/main/Styles';
import FooterView from 'view/common/Footer';
import SideOverlay1 from 'view/common/SideOverlay1';
import APIUtils from 'common/utils/APIUtils';
import {GetRunningPopup} from 'common/utils/GraphQLUtils';

const MainPage = () => {
  const authReducer = useSelector(state => state.authReducer);
  const navigate = useNavigate();
  const [expand1, setExpand1] = useState(false);
  const [expand2, setExpand2] = useState(false);
  function showMainButton() {
    if (authReducer.isLogin) {
      return (
        <Button
          variant="standard"
          style={mainColoredBtn}
          onClick={() => {
            APIUtils.LoggerLog(
              authReducer.user_id,
              'keewil',
              'info',
              window.location.pathname,
              'navigate_mypage',
            );
            navigate('/mypage');
          }}
        >
          {'마이페이지'}
          <img
            src={iconArrowRight}
            alt="iconArrowRight"
            style={{marginLeft: '1rem', width: '1.5rem'}}
          />
        </Button>
      );
    }
    return (
      <Button
        variant="standard"
        style={mainColoredBtn}
        onClick={() => {
          APIUtils.LoggerLog(
            authReducer.user_id,
            'keewil',
            'info',
            window.location.pathname,
            'navigate_login',
          );
          navigate('/login');
        }}
      >
        {'회원가입/로그인'}
        <img
          src={iconArrowRight}
          alt="iconArrowRight"
          style={{marginLeft: '1rem', width: '1.5rem'}}
        />
      </Button>
    );
  }
  return (
    <Box
      style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}
    >
      <GetRunningPopup />
      <SideOverlay1 />
      <HeaderView />
      <Box
        style={{
          marginTop: '3rem',
          width: '70%',
          maxWidth: '80rem',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Box style={{flex: 4}}>
          <Box
            style={{
              fontSize: '1rem',
              color: '#49545c',
              marginTop: '12rem',
            }}
          >
            선생님 연결부터, 과제 관리까지 키위가 다 알아서,
          </Box>
          <Box
            style={{
              fontSize: '2.3rem',
              color: '#2f3941',
              fontWeight: '700',
              marginTop: '1rem',
            }}
          >
            <span style={{color: '#00C500'}}>간편하게</span> 제출하고
          </Box>
          <Box
            style={{fontSize: '2.3rem', color: '#2f3941', fontWeight: '700'}}
          >
            <span style={{color: '#00C500'}}>꼼꼼히</span> 첨삭받자!
          </Box>
          <Box style={{marginTop: '2rem', marginBottom: '5rem'}}>
            {showMainButton()}
          </Box>
        </Box>
        <Box
          style={{
            flex: 6,
            position: 'relative',
          }}
        >
          <img
            src={imgMain1}
            alt="imgMain1"
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              objectFit: 'contain',
              objectPosition: 'center center',
            }}
          />
          <img
            src={backgroundMain1}
            alt={'backgroundMain1'}
            style={{
              width: '120%',
              height: '100%',
              zIndex: '-1',
              position: 'absolute',
              objectFit: 'contain',
              objectPosition: 'center center',
              marginLeft: '-20%',
            }}
          />
        </Box>
      </Box>
      <Box
        style={{
          width: '70%',
          maxWidth: '80rem',
          marginTop: '10rem',
          marginBottom: '3rem',
          display: 'flex',
        }}
      >
        <Box style={{flex: '4'}}>
          <Box style={{color: '#00C500', fontWeight: '700'}}>AI 피드백</Box>
          <Box
            style={{
              fontSize: '1.75rem',
              fontWeight: '700',
              marginTop: '0.5rem',
              color: '#49545c',
            }}
          >
            AI 피드백을 반영해서 완성하는 글
          </Box>
          <Box style={{fontSize: '1rem', color: '#7d7d7d', marginTop: '1rem'}}>
            AI의 피드백을 받아 즉각적으로 글을 수정할 수 있습니다. 맞춤법,
            띄어쓰기, 중복된 어휘, 문장의 호응, 다양한 문장의 구성 등의 기본적인
            글의 형식을 갖출 수 있습니다.
          </Box>
          <Box style={{fontSize: '1rem', color: '#7d7d7d'}}>
            이후, 선생님께 제출한 글은 최종 리포트로 받을 수 있습니다. 리포트는
            web 화면과 pdf의 두 가지 방식으로 받을 수 있습니다. AI 글쓰기 연습을
            활용하세요.
          </Box>
          <Box style={{fontSize: '1rem', color: '#7d7d7d'}}>
            스스로 글을 완성해 나가는 힘, 키위 AI의 피드백이 도와드립니다.
          </Box>
        </Box>
        <Box
          style={{
            flex: '6',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img
            src={imgMain2}
            alt="imgMain2"
            style={{width: '80%', maxWidth: '25rem'}}
          />
        </Box>
      </Box>
      <Box
        style={{
          width: '70%',
          maxWidth: '80rem',
          marginTop: '7rem',
          marginBottom: '5rem',
          display: 'flex',
        }}
      >
        <Box style={{flex: '4'}}>
          <Box style={{color: '#00C500', fontWeight: '700'}}>
            선생님별 과제 관리
          </Box>
          <Box
            style={{
              fontSize: '1.75rem',
              fontWeight: '700',
              marginTop: '0.5rem',
              color: '#49545c',
            }}
          >
            선생님마다 강의실이 만들어져요!
          </Box>
          <Box style={{fontSize: '1rem', color: '#7d7d7d', marginTop: '1rem'}}>
            선생님께 인증 요청을 보내세요. 인증받은 키위런 계정은 ‘나의
            강의실’과 ‘AI 글쓰기 연습’이 활성화됩니다. 선생님께 과제를 받기
            위해서는 반드시 선생님 연결이 필요합니다.
          </Box>

          <Button
            style={{
              display: 'block',
              marginTop: '1rem',
              padding: '0.3rem 1rem',
              fontSize: '1rem',
              color: '#49545c',
              border: '1px solid #D8DCDE',
            }}
            onClick={() => {
              setExpand1(prev => !prev);
            }}
          >
            Q. 키위런을 단독으로 사용할 수 있나요?
          </Button>
          <Box
            style={{
              display: expand1 ? 'block' : 'none',
              margin: '1rem',
              color: '#68737D',
            }}
          >
            키위런은 학생 사용자 단독으로 사용할 수 없습니다. 키위티 선생님과
            연결해야만 사용할 수 있습니다. 연결된 선생님의 키위티 이용기간이
            끝나면 키위런 사용자도 서비스를 이용할 수 없습니다.
          </Box>
          <Button
            style={{
              display: 'block',
              marginTop: '1rem',
              padding: '0.3rem 1rem',
              fontSize: '1rem',
              color: '#49545c',
              border: '1px solid #D8DCDE',
            }}
            onClick={() => {
              setExpand2(prev => !prev);
            }}
          >
            Q. AI 글쓰기 연습은 어떻게 활용하나요?
          </Button>
          <Box
            style={{
              display: expand2 ? 'block' : 'none',
              margin: '1rem',
              color: '#68737D',
            }}
          >
            선생님과 연결된 학생들을 대상으로, ‘AI 글쓰기 연습’을 제공합니다.
            글의 종류를 선택하고, 제목과 본문을 입력하세요. AI 피드백을 받을 수
            있습니다. 글쓰기를 완성한 후, 키위챗과도 대화해 보세요. 단, AI
            글쓰기 연습은 저장되지 않습니다.
          </Box>
        </Box>
        <Box
          style={{
            flex: '6',
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'end',
          }}
        >
          <img src={imgMain3} alt="imgMain3" style={{width: '90%'}} />
        </Box>
      </Box>
      <FooterView />
    </Box>
  );
};
export default MainPage;
