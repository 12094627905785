import React from 'react';
import {Box, Button} from '@mui/material';
import logoKakao from 'asset/imageV2/logo_kakao2.png';
import logoKeewit from 'asset/image/logo_keewit.png';

const OverlayView = () => {
  return (
    <Box
      style={{
        paddingBottom: '2rem',
        position: 'fixed',
        bottom: 0,
        right: '5rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end',
        alignItems: 'end',
        zIndex: 3,
      }}
    >
      <Button
        style={{
          width: '5rem',
          height: '5rem',
          borderRadius: '50%',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#F9E000',
          color: 'black',
          fontSize: '0.8rem',
          fontWeight: 'bold',
        }}
        onClick={() =>
          window.open('http://pf.kakao.com/_XxbmzG/chat', '_blank')
        }
      >
        <img src={logoKakao} alt="logoKakao" style={{width: '2rem'}} />
        <Box>{'카톡상담'}</Box>
      </Button>
    </Box>
  );
};
export default OverlayView;
