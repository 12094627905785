import React, {useEffect, useState, useMemo} from 'react';
import {Box, Grid, TextField, Button, IconButton} from '@mui/material';
import {useLocation} from 'react-router-dom';
import APIUtils from 'common/utils/APIUtils';
import {AnalysisText} from 'view/result/AnalysisText';
import {StringUtils} from 'common/utils/StringUtils';
import {NumberUtils} from 'common/utils/NumberUtils';
import emojiRegex from 'emoji-regex';
import {
  MarginBoxComponent,
  TitleComponent,
  SingleLineComponent,
  SingleLineTitle,
  WhiteBox,
} from 'view/common/Components';
import ApiLoading from 'view/common/ApiLoading';
import CommentItem from 'view/comment/CommentItem';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {throttle} from 'lodash';
import HeaderView from 'view/common/Header';
import FooterView from 'view/common/Footer';
import RadarChart from 'view/common/RadarChart';
import EssayEditor from 'view/result/EssayEditor';
import PencilIcon2 from 'asset/imageV2/icon_pencil2.svg';
import ChatIcon from 'asset/imageV2/icon_chat.svg';

const MainPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const authReducer = useSelector(state => state.authReducer);

  const [commentParagraph, setCommentParagraph] = useState([]);
  const [highlightWords, setHighlightWords] = useState([]);

  const [loading, setLoading] = useState(true);
  const [onError, setOnError] = useState(false);
  const [currentText, setCurrentText] = useState(location.state.text);
  const [isScrollTop, setIsScrollTop] = useState(true);
  const [bottomOverlay, setBottomOverlay] = useState(true);

  const [practiceEvalID, setPracticeEvalID] = useState(0);
  const [chatUrl, setChatUrl] = useState('');

  const [writingJanre, setWritingJanre] = useState('');
  const [titleText, setTitleText] = useState('');
  const [titleEditable, setTitleEditable] = useState(false);

  const [labelText, setLabelText] = useState('');
  const [analyText, setAnalyText] = useState('');
  const [isGPT, setIsGPT] = useState(false);
  const [keewiScore, setKeewiScore] = useState({
    겹치는단어비율: 0,
    단어의평균길이: 0,
    문단별문장수: 0,
    문장별단어수: 0,
    분석오류문장수: 0,
    전체글자수: 0,
    전체문장수: 0,
    전체문단수: 0,
    흐름이자연스러운정도: 0,
    긴문장의수: 0,
    독자나이: 0,
  });
  const [radarData, setRadarData] = useState({
    labels: [],
    datasets: [],
  });

  const handleTitle = event => {
    let inputVal = event.target.value;
    const EMOJI_REGEX = emojiRegex();
    if (!EMOJI_REGEX.test(inputVal)) {
      if (inputVal.length > 100) {
        inputVal = inputVal.substring(0, 100);
      }
      setTitleText(inputVal);
    } else {
      alert('이모지는 입력이 불가능합니다.');
    }
  };

  const [tScore, setTScore] = useState(0);

  function setAnalysisInfoFromKeewiApiResult(res, anlayzedText) {
    console.log('Response----------------------------------------------------');
    console.log(res);
    const keewiScore = {
      겹치는단어비율: NumberUtils.getAnalysisNumber(
        res.data.eval_result.겹치는단어비율 * 100,
      ),
      단어의평균길이: NumberUtils.getAnalysisNumber(
        res.data.eval_result.단어의평균길이,
      ),
      문단별문장수: NumberUtils.getAnalysisNumber(
        res.data.eval_result.문단별문장수,
      ),
      문장별단어수: NumberUtils.getAnalysisNumber(
        res.data.eval_result.문장별단어수,
      ),
      분석오류문장수: res.data.eval_result.분석오류문장수,
      // 전체글자수: textLength,
      전체글자수: res.data.eval_result.전체글자수,
      전체문장수: res.data.eval_result.전체문장수,
      전체문단수: res.data.eval_result.전체문단수,
      흐름이자연스러운정도: NumberUtils.getAnalysisNumber(
        res.data.eval_result.흐름이자연스러운정도 * 100,
      ),
      긴문장의수: res.data.eval_result.긴문장의수 ?? 0,
      독자나이: res.data.eval_result.독자나이 ?? 0,
    };
    setKeewiScore(keewiScore);
    const wholeComment = [
      ...res.data.eval_result.trait_6_comment,
      ...res.data.eval_result.trait_5_comment,
      ...res.data.eval_result.trait_4_comment,
      ...res.data.eval_result.trait_3_comment,
      ...res.data.eval_result.trait_2_comment,
      ...res.data.eval_result.trait_1_comment,
    ];
    setHighlightWords(AnalysisText.createHighlightWordList(wholeComment));
    setCommentParagraph(
      AnalysisText.createComment(
        anlayzedText,
        res.data.eval_result.paragraph_texts,
        wholeComment,
      ),
    );
    setLabelText(AnalysisText.createHtmlTextLabel(res.data.eval_result.type));
    setAnalyText(
      AnalysisText.createHtmlTextAnaly(
        res.data.eval_result.paragraph_texts,
        res.data.eval_result.type,
      ),
    );
    setTScore(NumberUtils.getAnalysisNumber(res.data.eval_result.keewi_score));
    setIsGPT(res.data.eval_result.is_gpt_text);

    let datasets = [];
    datasets = [
      {
        data: [
          res.data.eval_result.trait_1_score,
          res.data.eval_result.trait_2_score,
          res.data.eval_result.trait_3_score,
          res.data.eval_result.trait_4_score,
          res.data.eval_result.trait_5_score,
          res.data.eval_result.trait_6_score,
        ],
        fill: true,
        backgroundColor: ({chart: {ctx}}) => {
          const bg = ctx.createLinearGradient(90, 90, 200, 200);
          bg.addColorStop(0, 'rgba(178, 223, 51, 0.5)');
          bg.addColorStop(1, 'rgba(255, 153, 0, 0.5)');
          return bg;
        },
        borderColor: '#FF9900',
        borderWidth: 1,
        pointStyle: 'circle',
        pointBackgroundColor: 'rgba(255, 138, 0, 1)',
        pointBorderColor: 'rgba(255, 255, 255, 0)',
      },
    ];
    const newRadarData = {
      labels: ['문법', '어휘', '표현', '문장', '조직', '주제'],
      datasets: datasets,
    };
    setRadarData(newRadarData);
  }

  function analyzeEvent(new_title, new_janre, new_text, new_practice_eval_id) {
    const trimmedText = StringUtils.getTrimmedBody(new_text);
    setLoading(true);
    setCurrentText(new_text);
    setTitleText(new_title);
    setWritingJanre(new_janre);
    const fetAnalasisResult = async (inputText, inputTitle, inputJanre) => {
      try {
        const response = await APIUtils.PracticeEval(
          authReducer.student_id,
          inputTitle,
          inputJanre,
          inputText,
        );
        return response;
      } catch (err) {
        console.log(err);
        setOnError(true);
      }
    };
    const saveResult = async result => {
      try {
        const response = await APIUtils.PracticeEvalSave(
          authReducer.student_id,
          result,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    const modifySaveResult = async result => {
      try {
        const response = await APIUtils.PracticeModifySave(
          authReducer.student_id,
          new_practice_eval_id,
          result,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    const keewiChatResult = async new_eval_id => {
      try {
        const response = await APIUtils.PracticeEvalKeewichat(
          authReducer.student_id,
          new_eval_id,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    fetAnalasisResult(trimmedText, new_title, new_janre)
      .then(res => {
        if (res.data.ret_code == 1000) {
          setAnalysisInfoFromKeewiApiResult(res, trimmedText);
          const jsonResult = AnalysisText.createSavingJsonFromEvalResult(
            res.data.eval_result,
            new_title,
            trimmedText,
            '성인',
            authReducer.student_name,
          );
          if (new_practice_eval_id == undefined || new_practice_eval_id == 0) {
            return saveResult(jsonResult);
          } else {
            modifySaveResult(jsonResult);
          }
        }
        return;
      })
      .then(re => {
        if (re == undefined) {
          setLoading(false);
          return;
        }
        if (re.data.ret_code == 1000) {
          localStorage.removeItem('practiceTitle');
          localStorage.removeItem('practiceBody');
          localStorage.removeItem('practiceJanre');
          setPracticeEvalID(re.data.practice_eval_id);
          return keewiChatResult(re.data.practice_eval_id);
        }
        setLoading(false);
        return;
      })
      .then(r => {
        if (r == undefined) return;
        if (r.data.ret_code == 1000) {
          setChatUrl(r.data.keewichat_url);
        }
        setLoading(false);
      });
  }
  function createWholeStatisticsText() {
    let flowLevel = '하';
    if (keewiScore.흐름이자연스러운정도 >= 71) flowLevel = '상';
    else if (keewiScore.흐름이자연스러운정도 >= 68) flowLevel = '중';
    return (
      <Box style={{width: '90%', marginBottom: '3rem'}}>
        <Box>
          {createStatisticsText('전체 글자 수', keewiScore.전체글자수, '자')}
        </Box>
        <Box>
          {createStatisticsText('전체 문단 수', keewiScore.전체문단수, '개')}
        </Box>
        <Box>
          {createStatisticsText('전체 문장 수', keewiScore.전체문장수, '개')}
        </Box>
        <Box>
          {createStatisticsText(
            '문단별 문장 수',
            keewiScore.문단별문장수,
            '개',
          )}
        </Box>
        <Box>
          {createStatisticsText(
            '긴 문장(50자)의 수',
            keewiScore.긴문장의수,
            '개',
          )}
        </Box>
        <Box>
          {createStatisticsText('예상 독자 나이', keewiScore.독자나이, '세')}
        </Box>
        <Box>{createStatisticsText('읽기 쉬운 정도', flowLevel, '')}</Box>
      </Box>
    );
  }
  function createStatisticsText(title, score, rest) {
    return (
      <Box
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '0.4rem',
          fontSize: '1rem',
          fontWeight: '500',
        }}
      >
        <Box>{title}</Box>
        <Box>
          <span style={{color: '#f67f10'}}>{score}</span> {rest}
        </Box>
      </Box>
    );
  }
  function showSaveOverlay() {
    if (bottomOverlay) {
      return (
        <Box
          style={{
            // width: '100%',
            paddingBottom: '2rem',
            position: 'fixed',
            bottom: 0,
            right: '5rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end',
            zIndex: 3,
          }}
        >
          <IconButton
            style={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: '#279EFF',
              border: 'solid 2px #279EFF',
              width: '5rem',
              height: '5rem',
              color: 'white',
              fontSize: '0.8rem',
            }}
            onClick={() => {
              setBottomOverlay(false);
            }}
          >
            <img src={PencilIcon2} alt="PencilIcon2" />
            수정하기
          </IconButton>
          <IconButton
            style={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: '#00C500',
              border: 'solid 2px #00C500',
              width: '5rem',
              height: '5rem',
              color: 'white',
              fontSize: '0.8rem',
              marginTop: '1rem',
            }}
            onClick={() => {
              window.open(chatUrl);
            }}
          >
            <img src={ChatIcon} alt="ChatIcon" />
            키위챗과 대화
          </IconButton>
        </Box>
      );
    }
    return (
      <EssayEditor
        currentText={currentText}
        analyzeEvent={bodyText => {
          window.localStorage.setItem('title', titleText);
          window.localStorage.setItem('text', bodyText);
          window.localStorage.setItem('janre', writingJanre);
          location.state.title = titleText;
          location.state.text = bodyText;
          location.state.writing_janre = writingJanre;
          analyzeEvent(titleText, writingJanre, bodyText, practiceEvalID);
          setBottomOverlay(true);
        }}
        cancelEvent={() => {
          setBottomOverlay(true);
        }}
        highlightWordList={highlightWords}
      />
    );
  }
  function showGPTValidation() {
    if (isGPT) {
      return (
        <Box
          style={{
            width: '100%',
            backgroundColor: '#f5d7da',
            color: '#bb0000',
            fontSize: '1rem',
            padding: '0.5rem',
            textAlign: 'center',
          }}
        >
          ⚠ GPT로 생성한 글일 가능성이 높습니다.
        </Box>
      );
    }
  }
  function showFooter() {
    if (bottomOverlay) {
      return <FooterView />;
    }
  }
  const throttledScroll = useMemo(
    () =>
      throttle(() => {
        if (window.scrollY > 500) {
          setIsScrollTop(false);
        } else {
          setIsScrollTop(true);
        }
      }, 1),
    [isScrollTop],
  );
  useEffect(() => {
    window.addEventListener('scroll', throttledScroll);
    return () => {
      window.removeEventListener('scroll', throttledScroll); //clean up
    };
  }, [throttledScroll]);
  useEffect(() => {
    let savedText = '';
    let savedTitle = '';
    let savedJanre = '';
    if (location.state == null && window.localStorage.getItem('text') == null) {
      navigate('/', {replace: true});
      return;
    } else if (window.localStorage.getItem('text') != null) {
      savedTitle = window.localStorage.getItem('title');
      savedText = window.localStorage.getItem('text');
      savedJanre = window.localStorage.getItem('janre');
    } else {
      savedTitle = location.state.title;
      savedText = location.state.text;
      savedJanre = location.state.writing_janre;
      window.localStorage.setItem('title', location.state.title);
      window.localStorage.setItem('text', location.state.text);
      window.localStorage.setItem('janre', location.state.writing_janre);
    }
    analyzeEvent(savedTitle, savedJanre, savedText, 0);
  }, []);
  // if (onError) return <ServerError />;
  if (loading) return <ApiLoading loading={loading} />;
  return (
    <Box
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <HeaderView />
      <TitleComponent
        text1="AI 글쓰기 연습"
        text2="KEEwi AI와 함께 글쓰기 연습을 해봅시다. 쓰기 활동 후 키위챗과 대화할 수 있습니다."
      />
      <Box style={{marginTop: '5rem'}}></Box>
      {showGPTValidation()}
      <MarginBoxComponent>
        <>
          <Box
            style={{
              borderBottom: '1px solid #7d7d7d',
              marginTop: '2rem',
              marginBottom: '3rem',
              paddingBottom: '1rem',
            }}
          >
            <SingleLineComponent
              stepNumber="02"
              text={'AI 피드백을 참고하여 글을 수정하세요.'}
            />
            <SingleLineTitle
              title={titleText}
              tScore={tScore}
              handleInput={handleTitle}
              editable={titleEditable}
              changeEditStatus={status => {
                setTitleEditable(status);
              }}
            />
          </Box>

          <Grid container spacing={'1rem'}>
            <Grid
              item
              xs={7}
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box
                style={{
                  fontSize: '1.375rem',
                  fontWeight: '700',
                  marginBottom: '1.5rem',
                }}
              >
                내용 분석
              </Box>
              <WhiteBox>
                <>
                  {labelText}
                  {analyText}
                </>
              </WhiteBox>
            </Grid>
            <Grid
              item
              xs={5}
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box
                style={{
                  fontSize: '1.375rem',
                  fontWeight: '700',
                  marginBottom: '1.5rem',
                }}
              >
                글 통계 자료
              </Box>
              <WhiteBox>
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'start',
                    height: '100%',
                  }}
                >
                  {createWholeStatisticsText()}
                  <Box
                    style={{
                      width: '100%',
                      minHeight: '20rem',
                      backgroundColor: '#F7F8FA',
                      borderRadius: '1.25rem',
                      padding: '1.25rem 0rem',
                    }}
                  >
                    <RadarChart radarData={radarData} />
                  </Box>
                </Box>
              </WhiteBox>
            </Grid>
          </Grid>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '2rem',
              marginTop: '3rem',
            }}
          >
            {commentParagraph.map((paragraph, paragraphIndex) => {
              if (paragraph.length <= 2) {
                return;
              }
              if (paragraph.length == 3 && paragraphIndex == 1) {
                return;
              }
              return paragraph.map((value, idx) => {
                return (
                  <CommentItem
                    key={'commentbox' + paragraphIndex + '_' + idx}
                    paragraphIndex={paragraphIndex}
                    idx={idx}
                    commentID={value.commentID}
                    type={value.type}
                    A={value.A}
                    B={value.B}
                    C={value.C}
                    subtype={value.subtype}
                    text={value.text}
                  />
                );
              });
            })}
          </Box>
        </>
      </MarginBoxComponent>
      {showSaveOverlay()}
      {showFooter()}
    </Box>
  );
};
export default MainPage;
