import React, {useState} from 'react';
import {Box, Button} from '@mui/material';

import {useDispatch, useSelector} from 'react-redux';
import {removeLogin} from 'common/reducer/auth';
import logo from 'asset/imageV2/logo_keewi_learn.png';
import logoSoluny from 'asset/imageV2/logo_keewil_soluny.png';
import {headerBox2, headerBox3} from 'view/login/Styles';
import {useNavigate, useLocation} from 'react-router-dom';
import iconArrowDown from 'asset/imageV2/icon_arrow_down.svg';
import APIUtils from 'common/utils/APIUtils';

const HeaderView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const authReducer = useSelector(state => state.authReducer);
  const dispatch = useDispatch();
  const isLogin = authReducer.isLogin;
  const [showExtension, setShowExtension] = useState(false);
  const isSoluny = authReducer.student_email.startsWith('soluny');
  function onLogin() {
    APIUtils.LoggerLog(
      authReducer.user_id,
      'keewil',
      'info',
      window.location.pathname,
      'navigate_login',
    );
    navigate('/login');
  }
  if (isLogin) {
    return (
      <Box style={headerBox2}>
        <Box style={headerBox3}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box>
              <img
                src={isSoluny ? logoSoluny : logo}
                alt={'logoT'}
                style={{height: '60px'}}
                onClick={() => {
                  APIUtils.LoggerLog(
                    authReducer.user_id,
                    'keewil',
                    'info',
                    window.location.pathname,
                    'navigate_home',
                  );
                  navigate('/', {replace: true});
                }}
              />
            </Box>
          </Box>

          <Box style={{display: 'flex'}}>
            <Button
              style={{
                color: '#2f3941',
              }}
              onClick={() => {
                window.open(
                  'https://blog.naver.com/PostList.naver?blogId=twoblockai&from=postList&categoryNo=20',
                );
              }}
            >
              키위 AI란?
            </Button>
            <Button
              style={{
                color: '#2f3941',
              }}
              onClick={() => {
                window.open('https://korean.ai/main/');
              }}
            >
              온라인 AI 글쓰기대회
            </Button>
            <Box style={{position: 'relative'}}>
              <Button
                style={{
                  marginLeft: '1rem',
                  color: '#000000',
                  fontWeight: 'bold',
                }}
                onClick={() => {
                  setShowExtension(!showExtension);
                }}
              >
                {authReducer.student_name}
                <img
                  src={iconArrowDown}
                  alt="iconArrowDown"
                  style={{marginLeft: '1rem'}}
                />
              </Button>
              <Box
                style={{
                  display: showExtension ? 'flex' : 'none',
                  flexDirection: 'column',
                  alignItems: 'start',
                  position: 'absolute',
                  backgroundColor: 'white',
                  boxShadow: '4px 10px 15px 9px rgba(173, 173, 173, 0.10)',
                  border: '1px solid var(--01-gray-07, #E9EBED)',
                  borderRadius: '0.625rem',
                  whiteSpace: 'nowrap',
                  padding: '0.5rem 1rem',
                  zIndex: 2,
                }}
              >
                <Button
                  style={{color: '#49545C'}}
                  onClick={() => {
                    if (location.pathname == '/mypage') {
                      navigate(0);
                    } else {
                      APIUtils.LoggerLog(
                        authReducer.user_id,
                        'keewil',
                        'info',
                        window.location.pathname,
                        'navigate_mypage',
                      );
                      navigate('/mypage');
                    }
                  }}
                >
                  마이페이지
                </Button>
                <Button
                  style={{color: '#49545C'}}
                  onClick={() => {
                    APIUtils.LoggerLog(
                      authReducer.user_id,
                      'keewil',
                      'info',
                      window.location.pathname,
                      'logout_and_navigate_home',
                    );
                    dispatch(removeLogin());
                    navigate('/');
                  }}
                >
                  로그아웃
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  } else {
    return (
      <Box style={headerBox2}>
        <Box style={headerBox3}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box>
              <img
                src={logo}
                alt={'logoT'}
                style={{height: '60px'}}
                onClick={() => {
                  APIUtils.LoggerLog(
                    authReducer.user_id,
                    'keewil',
                    'info',
                    window.location.pathname,
                    'navigate_home',
                  );
                  navigate('/', {replace: true});
                }}
              />
            </Box>
          </Box>
          <Box>
            <Button
              style={{
                color: '#2f3941',
              }}
              onClick={() => {
                window.open(
                  'https://blog.naver.com/PostList.naver?blogId=twoblockai&from=postList&categoryNo=20',
                );
              }}
            >
              키위 AI란?
            </Button>
            <Button
              style={{
                color: '#2f3941',
              }}
              onClick={() => {
                window.open('https://korean.ai/main/');
              }}
            >
              온라인 AI 글쓰기대회
            </Button>
            <Button
              style={{
                marginLeft: '3rem',
                backgroundColor: '#00C500',
                color: 'white',
                borderRadius: '6.25rem',
                padding: '0.875rem 1.25rem',
              }}
              onClick={() => {
                onLogin();
              }}
            >
              로그인/회원가입
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }
};
export default HeaderView;
