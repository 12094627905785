import PropTypes from 'prop-types';
import {
  Grid,
  Box,
  FormControl,
  Button,
  TextField,
  IconButton,
  Tooltip,
} from '@mui/material';
import {Palette} from 'common/utils/ColorUtils';
import checkMark from 'asset/image/icon_check.png';
import bullet from 'asset/image/icon_snow.png';
import pencil from 'asset/image/icon_pencil.png';
import backgroundMain3 from 'asset/imageV2/background_main3.svg';
import PencilIcon3 from 'asset/imageV2/icon_pencil3.svg';
import Linkify from 'linkify-react';
import ArrowGreen1 from 'asset/imageV2/icon_green_arrow1.svg';
import ArrowGreen2 from 'asset/imageV2/icon_green_arrow2.svg';
import ArrowGrey1 from 'asset/imageV2/icon_grey_arrow1.svg';
import {useState} from 'react';

export const MarginBoxComponent = props => {
  return (
    <Box
      style={{
        marginBottom: '5%',
        marginTop: '2%',
        width: '70%',
        maxWidth: '80rem',
      }}
    >
      {props.children}
    </Box>
  );
};
MarginBoxComponent.propTypes = {
  children: PropTypes.element,
};

export const TitleComponent = props => {
  return (
    <Box
      style={{
        width: '100%',
        backgroundColor: '#ECFFE9',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        paddingTop: '7rem',
        paddingBottom: '4rem',
        marginBottom: '-4rem',
      }}
    >
      <Box
        style={{
          fontSize: '2rem',
          fontWeight: 'bold',
          backgroundColor: 'white',
          border: '3px solid #00C500',
          padding: '1rem 8rem',
          borderRadius: '5.5rem',
        }}
      >
        {props.text1}
      </Box>
      <Box
        style={{
          textAlign: 'center',
          color: '#68737D',
          marginTop: '1rem',
          marginBottom: '3rem',
        }}
      >
        {props.text2}
      </Box>
    </Box>
  );
};
TitleComponent.propTypes = {
  text1: PropTypes.string,
  text2: PropTypes.string,
  text3: PropTypes.string,
};
export const TitleComponent2 = props => {
  return (
    <Box
      style={{
        width: '100%',
        backgroundColor: '#ECFFE9',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        paddingTop: '7rem',
        paddingBottom: '7rem',
        marginBottom: '-5rem',
      }}
    >
      <Box style={{display: 'flex', fontSize: '1rem', color: '#68737D'}}>
        <Box
          style={{
            fontSize: '0.875rem',
            backgroundColor: '#68737D',
            color: 'white',
            padding: '0.125rem 0.5rem',
            borderRadius: '0.31rem',
            marginRight: '1rem',
          }}
        >
          {'D-' + props.dateLeft}
        </Box>
        {props.dateText}
      </Box>
      <Box style={{marginTop: '1rem', fontSize: '2rem', fontWeight: 'bold'}}>
        {props.titleText}
      </Box>
      <Box
        style={{
          width: '70%',
          maxWidth: '80rem',
          marginTop: '2rem',
          padding: '1rem',
          backgroundColor: 'white',
          color: '#4b4d4c',
          border: '2px solid #00C500',
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-all',
        }}
      >
        <Linkify
          options={{
            target: '-blank',
          }}
        >
          {props.messageText}
        </Linkify>

        <Box
          style={{
            display:
              props.textCountChecked || props.requiredWordChecked ? '' : 'none',
            marginLeft: '1rem',
            marginTop: '1rem',
            fontWeight: 'bold',
          }}
        >
          <Box
            style={{
              display: props.textCountChecked ? '' : 'none',
            }}
          >
            {'\u2022 필수 분량 : ' +
              props.textCountMin +
              ' 자 ~ ' +
              props.textCountMax +
              ' 자'}
          </Box>
          <Box
            style={{
              display: props.requiredWordChecked ? '' : 'none',
            }}
          >
            {'\u2022 필수 키워드 : ' + props.requiredWord}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
TitleComponent2.propTypes = {
  dateLeft: PropTypes.number,
  dateText: PropTypes.string,
  titleText: PropTypes.string,
  messageText: PropTypes.string,
  textCountChecked: PropTypes.bool,
  textCountMin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textCountMax: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  requiredWordChecked: PropTypes.bool,
  requiredWord: PropTypes.string,
};
export const TitleComponent3 = props => {
  return (
    <Box
      style={{
        width: '100%',
        backgroundColor: '#ECFFE9',
        // backgroundImage: `url(${backgroundMain3})`,
        // backgroundRepeat: 'no-repeat',
        // backgroundSize: 'contain',
        // backgroundPosition: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        paddingTop: '7rem',
        paddingBottom: '10rem',
        marginBottom: '-5rem',
      }}
    >
      <Box style={{fontSize: '1rem', color: '#68737D'}}>{props.dateText}</Box>
      <Box style={{marginTop: '1rem', fontSize: '2rem', fontWeight: 'bold'}}>
        {props.titleText}
      </Box>
      <Box
        style={{
          width: '70%',
          maxWidth: '80rem',
          marginTop: '2rem',
          padding: '1rem',
          backgroundColor: 'white',
          color: '#4b4d4c',
          border: '2px solid #00C500',
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-all',
        }}
      >
        <Linkify
          options={{
            target: '-blank',
          }}
        >
          {props.messageText}
        </Linkify>

        <Box
          style={{
            display:
              props.textCountChecked || props.requiredWordChecked ? '' : 'none',
            marginLeft: '1rem',
            marginTop: '1rem',
            fontWeight: 'bold',
          }}
        >
          <Box
            style={{
              display: props.textCountChecked ? '' : 'none',
            }}
          >
            {'\u2022 필수 분량 : ' +
              props.textCountMin +
              ' 자 ~ ' +
              props.textCountMax +
              ' 자'}
          </Box>
          <Box
            style={{
              display: props.requiredWordChecked ? '' : 'none',
            }}
          >
            {'\u2022 필수 키워드 : ' + props.requiredWord}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
TitleComponent3.propTypes = {
  dateText: PropTypes.string,
  titleText: PropTypes.string,
  messageText: PropTypes.string,
  textCountChecked: PropTypes.bool,
  textCountMin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textCountMax: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  requiredWordChecked: PropTypes.bool,
  requiredWord: PropTypes.string,
};
export const BookComponent = props => {
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <Box
        style={{
          maxWidth: '80rem',
          minWidth: '30rem',
          width: '70%',
          minHeight: '50px',
          backgroundColor: 'white',
          borderRadius: '1.25rem',
          border: '1px solid var(--01-gray-07, #E9EBED)',
          boxShadow: '4px 5px 18px 5px rgba(111, 121, 128, 0.08)',
          paddingLeft: '7%',
          paddingRight: '7%',
          paddingTop: '5rem',
          paddingBottom: '3rem',
          marginBottom: '5rem',
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
};
BookComponent.propTypes = {
  children: PropTypes.element,
};
export const WhiteBox = props => {
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        flex: 1,
      }}
    >
      <Box
        style={{
          width: '90%',
          backgroundColor: 'white',
          borderRadius: '1.25rem',
          border: '1px solid var(--01-gray-07, #E9EBED)',
          boxShadow: '4px 5px 18px 5px rgba(111, 121, 128, 0.08)',
          paddingLeft: '5%',
          paddingRight: '5%',
          paddingTop: '2rem',
          paddingBottom: '2rem',
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
};
WhiteBox.propTypes = {
  children: PropTypes.element,
};
export const LayerBoxComponent = props => {
  return (
    <FormControl fullWidth>
      <Box
        style={{
          fontWeight: 'bold',
          color: 'black',
          marginLeft: '0.5rem',
        }}
      >
        {props.title} <span style={{color: '#35D2BB'}}>*</span>
      </Box>
      <Box
        style={{
          minHeight: '3rem',
          paddingTop: '0.5rem',
          paddingLeft: '0.5rem',
        }}
      >
        {props.children}
      </Box>
    </FormControl>
  );
};
LayerBoxComponent.propTypes = {
  title: PropTypes.string,
  children: PropTypes.element,
};
export const LayerBoxComponent2 = props => {
  return (
    <FormControl
      fullWidth
      style={{
        backgroundColor: Palette.green1,
        borderRadius: 10,
        height: '100%',
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
        }}
      >
        <Box
          style={{
            marginTop: '0.5rem',
            marginLeft: '1rem',
            marginBottom: '0.5rem',
            marginRight: '0.5rem',
          }}
        >
          <img src={bullet} alt={'bullet'} style={{width: '1.5rem'}} />
        </Box>
        <Box
          style={{
            flex: 1,
            fontSize: '1.2rem',
            fontWeight: 'bold',
            color: Palette.white,
          }}
        >
          {' '}
          {props.title}
        </Box>
      </Box>
      <Box
        style={{
          backgroundColor: Palette.white,
          margin: 3,
          padding: '2rem',
          borderRadius: 10,
          height: '100%',
          wordWrap: 'break-word',
        }}
      >
        {props.children}
      </Box>
    </FormControl>
  );
};
LayerBoxComponent2.propTypes = {
  title: PropTypes.string,
  children: PropTypes.element,
};
export const LayerBoxComponent3 = props => {
  return (
    <Box
      style={{
        border: '3px solid #E9EBED', //+ Palette.orange1,
        borderRadius: '1.25rem',
        backgroundColor: Palette.orange1,
      }}
    >
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          marginTop: '0.5rem',
          marginBottom: '0.5rem',
          marginLeft: '1rem',
        }}
      >
        <img src={bullet} alt={'bullet'} style={{height: '25px'}} />
        <Box
          style={{
            fontSize: 'calc(0.6rem + 0.8vw)',
            fontWeight: 'bold',
            color: Palette.white,
            marginLeft: '0.5rem',
          }}
        >
          {props.title}
        </Box>
      </Box>
      <Box
        style={{
          minHeight: '3rem',
          borderBottomRightRadius: '1rem',
          borderBottomLeftRadius: '1rem',
          backgroundColor: Palette.white,
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
};
LayerBoxComponent3.propTypes = {
  title: PropTypes.string,
  children: PropTypes.element,
};
export const BorderBoxComponent = props => {
  return (
    <Box
      style={{
        boxSizing: 'border-box',
        borderRadius: 10,
        border: '3px solid ' + Palette.green1,
        height: '100%',
        // marginLeft: '2rem',
      }}
    >
      {props.children}
    </Box>
  );
};
BorderBoxComponent.propTypes = {
  children: PropTypes.element,
};
const disabledStyles = theme => ({
  disabledButton: {
    backgroundColor: 'red',
  },
});
export const CenteredButton = props => {
  return (
    <Box
      style={{
        marginTop: '3rem',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Button
        variant="filled"
        style={{
          cursor: props.disabled ? 'default' : 'pointer',
          color: 'white',
          backgroundColor: props.disabled ? '#87929D' : '#00C500',
          borderRadius: '6.25rem',
          padding: '0.9375rem 1.5625rem',
          fontWeight: 'bold',
          fontSize: '1.125rem',
          boxShadow: '0px 5px 10px 3px rgba(0, 0, 0, 0.15)',
          zIndex: 2,
        }}
        onClick={() => {
          props.clickEvent(props.disabled);
        }}
      >
        {props.title}
      </Button>
    </Box>
  );
};
CenteredButton.propTypes = {
  title: PropTypes.string,
  clickEvent: PropTypes.func,
  disabled: PropTypes.bool,
};

export const SingleLineComponent = props => {
  return (
    <Box
      style={{
        display: 'flex',
        whiteSpace: 'nowrap',
        alignItems: 'center',
        marginTop: '1rem',
      }}
    >
      <Box
        style={{
          fontWeight: 'bold',
          color: 'white',
          backgroundColor: '#ff9900',
          padding: '0.3rem 0.75rem',
          borderRadius: '6.25rem',
          marginRight: '0.625rem',
        }}
      >
        {'STEP ' + props.stepNumber}
      </Box>
      <Box style={{fontSize: '1.375rem', fontWeight: 'bold', color: '#2f3941'}}>
        {props.text}
      </Box>
    </Box>
  );
};
SingleLineComponent.propTypes = {
  stepNumber: PropTypes.string,
  text: PropTypes.string,
};
export const SingleLineComponent2 = props => {
  const [leftHover, setLeftHover] = useState(false);
  const [rightHover, setRightHover] = useState(false);
  return (
    <Box
      style={{
        display: 'flex',
        whiteSpace: 'nowrap',
        alignItems: 'center',
        marginTop: '1rem',
      }}
    >
      <Box
        style={{
          fontWeight: 'bold',
          color: 'white',
          backgroundColor: '#ff9900',
          padding: '0.3rem 0.75rem',
          borderRadius: '6.25rem',
          marginRight: '0.625rem',
        }}
      >
        {'STEP ' + props.stepNumber}
      </Box>
      <Box style={{fontSize: '1.375rem', fontWeight: 'bold', color: '#2f3941'}}>
        {props.text}
      </Box>
      <Box style={{flex: 1}}></Box>
      <Box>
        <Tooltip
          title="이전 글"
          onMouseEnter={() => {
            setLeftHover(true);
          }}
          onMouseLeave={() => {
            setLeftHover(false);
          }}
        >
          <span>
            <IconButton
              disabled={props.currentIndex <= 1}
              onClick={() => props.leftClickEvent()}
            >
              <img
                src={
                  props.currentIndex <= 1
                    ? ArrowGrey1
                    : leftHover
                    ? ArrowGreen2
                    : ArrowGreen1
                }
                style={{rotate: '180deg'}}
              ></img>
            </IconButton>
          </span>
        </Tooltip>
        <span style={{fontWeight: 'bold'}}>
          {props.currentIndex + '/' + props.maxIndex}
        </span>
        <Tooltip
          title="다음 글"
          onMouseEnter={() => {
            setRightHover(true);
          }}
          onMouseLeave={() => {
            setRightHover(false);
          }}
        >
          <span>
            <IconButton
              disabled={props.currentIndex >= props.maxIndex}
              onClick={() => props.rightClickEvent()}
            >
              <img
                src={
                  props.currentIndex >= props.maxIndex
                    ? ArrowGrey1
                    : rightHover
                    ? ArrowGreen2
                    : ArrowGreen1
                }
              ></img>
            </IconButton>
          </span>
        </Tooltip>
      </Box>
    </Box>
  );
};
SingleLineComponent2.propTypes = {
  stepNumber: PropTypes.string,
  text: PropTypes.string,
  currentIndex: PropTypes.number,
  maxIndex: PropTypes.number,
  leftClickEvent: PropTypes.func,
  rightClickEvent: PropTypes.func,
};
export const SingleLineComponent3 = props => {
  const [leftHover, setLeftHover] = useState(false);
  const [rightHover, setRightHover] = useState(false);
  return (
    <Box
      style={{
        display: 'flex',
        whiteSpace: 'nowrap',
        alignItems: 'center',
        // marginTop: '6rem',
        marginTop: props.marginTop,
        paddingBottom: '2rem',
        marginBottom: '3rem',
        borderBottom: '3px solid #E9EBED',
      }}
    >
      <Box
        style={{
          fontSize: '1.375rem',
          fontWeight: 'bold',
          color: '#00C500',
        }}
      >
        {props.text}
      </Box>
      <Box style={{flex: 1}}></Box>
      <Box>
        <Tooltip
          title="이전 글"
          onMouseEnter={() => {
            setLeftHover(true);
          }}
          onMouseLeave={() => {
            setLeftHover(false);
          }}
        >
          <span>
            <IconButton
              disabled={props.currentIndex <= 1}
              onClick={() => props.leftClickEvent()}
            >
              <img
                src={
                  props.currentIndex <= 1
                    ? ArrowGrey1
                    : leftHover
                    ? ArrowGreen2
                    : ArrowGreen1
                }
                style={{rotate: '180deg'}}
              ></img>
            </IconButton>
          </span>
        </Tooltip>
        <span style={{fontWeight: 'bold'}}>
          {props.currentIndex + '/' + props.maxIndex}
        </span>
        <Tooltip
          title="다음 글"
          onMouseEnter={() => {
            setRightHover(true);
          }}
          onMouseLeave={() => {
            setRightHover(false);
          }}
        >
          <span>
            <IconButton
              disabled={props.currentIndex >= props.maxIndex}
              onClick={() => props.rightClickEvent()}
            >
              <img
                src={
                  props.currentIndex >= props.maxIndex
                    ? ArrowGrey1
                    : rightHover
                    ? ArrowGreen2
                    : ArrowGreen1
                }
              ></img>
            </IconButton>
          </span>
        </Tooltip>
      </Box>
    </Box>
  );
};
SingleLineComponent3.propTypes = {
  marginTop: PropTypes.string,
  text: PropTypes.string,
  currentIndex: PropTypes.number,
  maxIndex: PropTypes.number,
  leftClickEvent: PropTypes.func,
  rightClickEvent: PropTypes.func,
};

export const SingleLineTitle = props => {
  return (
    <Box style={{margin: '1rem 0rem'}}>
      <Box
        style={{
          marginTop: '2rem',
          display: props.editable ? 'none' : 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box style={{display: 'flex', alignItems: 'center'}}>
          <Box
            style={{
              fontSize: '1.5625rem',
              fontWeight: 'bold',
              color: 'black',
              marginLeft: '0.5rem',
            }}
          >
            {props.title}
          </Box>
          <IconButton
            style={{
              display: 'flex',
              width: '2rem',
              height: '2rem',
            }}
            onClick={() => {
              props.changeEditStatus(true);
            }}
          >
            <img src={PencilIcon3} alt={'pencil3'} style={{width: '1.5rem'}} />
          </IconButton>
        </Box>
        <Box style={{fontSize: '1.5rem', fontWeight: 'bold', color: '#ff9900'}}>
          {'현재 점수 : ' + props.tScore + '점'}
        </Box>
      </Box>
      <Box style={{textAlign: 'end', fontSize: '0.75rem', color: '#7d7d7d'}}>
        AI 점수는 내용적 평가가 포함되지 않습니다.
      </Box>
      <Box
        style={{
          display: props.editable ? 'flex' : 'none',
          paddingTop: '0.5rem',
          paddingLeft: '0.5rem',
        }}
      >
        <TextField
          autoComplete="off"
          size="small"
          variant="outlined"
          fullWidth
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: '0px solid black',
              },

              '&:hover fieldset': {
                border: '0px solid black',
              },
              '&.Mui-focused fieldset': {
                border: '0px solid black',
              },
            },
          }}
          InputProps={{
            style: {
              fontSize: '1rem',
              backgroundColor: '#F7F8FA',
              border: '1px solid var(--01-gray-07, #E9EBED)',
              boxShadow: '4px 5px 18px 5px rgba(111, 121, 128, 0.08)',
            },
          }}
          value={props.title}
          onChange={props.handleInput}
        ></TextField>
        <Button
          style={{
            backgroundColor: '#00C500',
            color: 'white',
            fontSize: 'bold',
            whiteSpace: 'nowrap',
            marginLeft: '1rem',
          }}
          onClick={() => {
            props.changeEditStatus(false);
          }}
        >
          변경하기
        </Button>
      </Box>
    </Box>
  );
};
SingleLineTitle.propTypes = {
  title: PropTypes.string,
  tScore: PropTypes.number,
  handleInput: PropTypes.func,
  editable: PropTypes.bool,
  changeEditStatus: PropTypes.func,
};
export const CustomTextField1 = props => {
  return (
    <FormControl fullWidth>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          style={{
            fontWeight: 'bold',
            color: 'black',
            marginLeft: '0.5rem',
          }}
        >
          {props.title} <span style={{color: '#35D2BB'}}>*</span>
        </Box>
      </Box>
      <Box
        style={{
          minHeight: '3rem',
          paddingTop: '0.5rem',
          paddingLeft: '0.5rem',
        }}
      >
        <TextField
          autoComplete="off"
          size="small"
          variant="outlined"
          fullWidth
          sx={{
            height: '3.3rem',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: '0px solid black',
              },

              '&:hover fieldset': {
                border: '0px solid black',
              },
              '&.Mui-focused fieldset': {
                border: '0px solid black',
              },
            },
          }}
          label={props.inputText == '' ? props.emptyText : ''}
          InputLabelProps={{
            style: {
              color: Palette.grey1,
              fontSize: '1rem',
            },
            shrink: false,
          }}
          InputProps={{
            style: {
              fontSize: '1rem',
              backgroundColor: '#F7F8FA',
              border: '1px solid var(--01-gray-07, #E9EBED)',
              boxShadow: '4px 5px 18px 5px rgba(111, 121, 128, 0.08)',
            },
          }}
          value={props.inputText}
          onChange={props.handleInput}
        ></TextField>
      </Box>
    </FormControl>
  );
};
CustomTextField1.propTypes = {
  title: PropTypes.string,
  emptyText: PropTypes.string,
  inputText: PropTypes.string,
  handleInput: PropTypes.func,
};
export const CustomTextField2 = props => {
  return (
    <FormControl fullWidth>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          style={{
            fontWeight: 'bold',
            color: 'black',
            marginLeft: '0.5rem',
          }}
        >
          {props.title} <span style={{color: '#00c500'}}>*</span>
        </Box>
      </Box>
      <Box
        style={{
          minHeight: '3rem',
          paddingTop: '0.5rem',
          paddingLeft: '0.5rem',
          position: 'relative',
        }}
      >
        <Box
          style={{
            position: 'absolute',
            zIndex: 3,
            right: '0.5rem',
            bottom: '1rem',
          }}
        >
          <span style={{color: '#00c500'}}>{props.inputText.length}</span>
          <span style={{color: '#A8B1B7'}}>/{props.maxCount}</span>
        </Box>
        <TextField
          autoComplete="off"
          size="small"
          variant="outlined"
          fullWidth
          sx={{
            height: '3.3rem',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: '0px solid black',
              },

              '&:hover fieldset': {
                border: '0px solid black',
              },
              '&.Mui-focused fieldset': {
                border: '0px solid black',
              },
            },
          }}
          label={props.inputText == '' ? props.emptyText : ''}
          InputLabelProps={{
            style: {
              color: Palette.grey1,
              fontSize: '1rem',
            },
            shrink: false,
          }}
          InputProps={{
            style: {
              fontSize: '1rem',
              backgroundColor: '#F7F8FA',
              border: '1px solid var(--01-gray-07, #E9EBED)',
              boxShadow: '4px 5px 18px 5px rgba(111, 121, 128, 0.08)',
            },
          }}
          value={props.inputText}
          onChange={props.handleInput}
        ></TextField>
      </Box>
    </FormControl>
  );
};
CustomTextField2.propTypes = {
  title: PropTypes.string,
  emptyText: PropTypes.string,
  inputText: PropTypes.string,
  handleInput: PropTypes.func,
  maxCount: PropTypes.string,
};
export const CustomTextField3 = props => {
  return (
    <FormControl fullWidth>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          style={{
            fontWeight: 'bold',
            color: 'black',
            marginLeft: '0.5rem',
          }}
        >
          {props.title} <span style={{color: '#00c500'}}>*</span>
        </Box>
      </Box>
      <Box
        style={{
          minHeight: '3rem',
          paddingTop: '0.5rem',
          paddingLeft: '0.5rem',
          position: 'relative',
        }}
      >
        <Box
          style={{
            position: 'absolute',
            zIndex: 3,
            right: '0.5rem',
            bottom: '1rem',
          }}
        >
          <span style={{color: '#00c500'}}>{props.inputText.length}</span>
          <span style={{color: '#A8B1B7'}}>/{props.maxCount}</span>
        </Box>
        <TextField
          autoComplete="off"
          multiline
          size="small"
          variant="outlined"
          fullWidth
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: '0px solid black',
              },
              '&:hover fieldset': {
                border: '0px solid black',
              },
              '&.Mui-focused fieldset': {
                border: '0px solid black',
              },
            },
          }}
          label={props.inputText == '' ? props.emptyText : ''}
          InputLabelProps={{
            style: {
              color: Palette.grey1,
              fontSize: '1rem',
              whiteSpace: 'pre-wrap',
            },
            shrink: false,
          }}
          InputProps={{
            style: {
              fontSize: '1rem',
              backgroundColor: '#F7F8FA',
              border: '1px solid var(--01-gray-07, #E9EBED)',
              boxShadow: '4px 5px 18px 5px rgba(111, 121, 128, 0.08)',
            },
          }}
          rows={20}
          value={props.inputText}
          onChange={props.handleInput}
        ></TextField>
      </Box>
    </FormControl>
  );
};
CustomTextField3.propTypes = {
  title: PropTypes.string,
  emptyText: PropTypes.string,
  inputText: PropTypes.string,
  handleInput: PropTypes.func,
  maxCount: PropTypes.string,
};
export const CommentBox = props => {
  return (
    // <Box
    //   style={{
    //     display: 'flex',
    //     justifyContent: 'center',
    //     width: '100%',
    //     flex: 1,
    //   }}
    // >
    <Box
      style={{
        width: '90%',
        backgroundColor: 'white',
        borderRadius: '1.25rem',
        border: '1px solid var(--01-gray-07, #E9EBED)',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        paddingLeft: '5%',
        paddingRight: '5%',
        paddingTop: '1rem',
        paddingBottom: '1rem',
      }}
    >
      {props.children}
    </Box>
    // </Box>
  );
};
CommentBox.propTypes = {
  children: PropTypes.element,
};
